<template>
  <div class="newSeeds">
    <!-- title -->
    <div class="title">
      <div class="title-top">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addMessage"
          >新增</el-button
        >
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="产品名称">
              <el-input
                v-model="logisticsForm.name"
                placeholder="请输入产品名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small"
            >刷新</el-button
          >
        </div>
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="removeForm"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <!-- table -->
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        width="100"
        label="序号"
        type="index"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="产品名称"
        prop="productName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="批次号"
        prop="batchNumber"
        align="center"
        show-overflow-tooltip
        :formatter="formatterBatch"
      >
      </el-table-column>
      <el-table-column
        label="销售量"
        prop="salesAmount"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="销售量单位"
        prop="salesAmountUnit"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="销售额"
        prop="salesVolume"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="销售额单位"
        prop="salesVolumeUnit"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <el-table-column
        label="销售流向"
        prop="salesFlow"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="负责人"
        prop="leadingCadre"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="时间"
        prop="startTime"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="二维码" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="seeBtn(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="updateClick(scope.$index, scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="del(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title=""
      :visible.sync="updateForm"
      width="30vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span
          style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;"
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="upTableData"
          :rules="rules"
          :model="upTableData"
          label-width="7vw"
        >
          <el-form-item label="产品名称" prop="productName">
            <el-input v-model.trim="upTableData.productName"></el-input>
          </el-form-item>
          <el-form-item label="批次号" prop="batchNumber">
            <!-- <el-input v-model.trim="upTableData.batchNumber"></el-input> -->
            <el-select
              v-model="upTableData.batchNumber"
              placeholder="请选择"
              style="width: 21vw"
              :formatter="formatterBatch"
            >
              <el-option
                v-for="item in batchList"
                :key="item.id"
                :label="item.batchNum"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售量" prop="salesAmount">
            <el-input type="number" v-model.trim="upTableData.salesAmount"></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="salesAmountUnit">
            <el-input v-model.trim="upTableData.salesAmountUnit"></el-input>
          </el-form-item>
          <el-form-item label="销售额" prop="salesVolume">
            <el-input type="number" v-model.trim="upTableData.salesVolume"></el-input>
          </el-form-item>
          <el-form-item label="销售额单位" prop="salesVolumeUnit">
            <el-input v-model.trim="upTableData.salesVolumeUnit"></el-input>
          </el-form-item>
          <el-form-item label="销售流向" prop="salesFlow">
            <el-input v-model.trim="upTableData.salesFlow"></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="leadingCadre">
            <el-input v-model.trim="upTableData.leadingCadre"></el-input>
          </el-form-item>
          <el-form-item label="时间" prop="startTime">
            <el-date-picker
              v-model="upTableData.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
              style="width:20vw"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 二维码 -->
    <el-dialog
      :modal="false"
      title="二维码"
      :visible.sync="codeShow"
      width="20%"
    >
      <div v-loading="loadingCode" id="qrcode" ref="qrCodeUrl"></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="codeShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      upTableData: {
        productName: "",
        batchNumber: "",
        salesAmount: "",
        salesAmountUnit: "",
        salesVolume: "",
        salesVolumeUnit:'',
        salesFlow: "",
        leadingCadre: "",
        startTime: "",
      },
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      searchStatus: false,
      logisticsForm: {
        names: "",
        region: -1,
      },
      updateForm: false,
      boxPrompt: "",
      rules: {
        productName: {
          required: true,
          message: "产品名称不可为空",
          trigger: "blur",
        },
        batchNumber: {
          required: true,
          message: "批次号不能为空",
          trigger: "blur",
        },
        salesAmount: {
          required: true,
          message: "销售量不能为空",
          trigger: "blur",
        },
        salesAmountUnit: {
          required: true,
          message: "规格不能为空",
          trigger: "blur",
        },
        salesVolume: {
          required: true,
          message: "销售额不能为空",
          trigger: "blur",
        },
        salesVolumeUnit:{
          required: true,
          message: "销售额单位不能为空",
          trigger: "blur",
        },
        salesFlow: {
          required: true,
          message: "销售流向不能为空",
          trigger: "blur",
        },
        leadingCadre: {
          required: true,
          message: "负责人不能为空",
          trigger: "blur",
        },
        startTime: {
          required: true,
          message: "质检时间不能为空",
          trigger: "change",
        },
      },
      index: 0,
      codeShow: false,
      loadingCode: true,
      sourCodeUrl: "http://119.188.246.129:20023/#/productH5", // 线上地址
      batchList: [],
    };
  },
  mounted() {
    this.requrstList();
    this.getBatchList();
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.size = val;
      this.requrstList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
    //请求列表数据
    requrstList(where) {
      this.$get("/salesMan/getSalesList", {
        page: this.page,
        size: this.size,
        productName: where || "",
      }).then((res) => {
        // //console.log(res);
        if (res.data.state == "success") {
          this.tableData = [];
          this.total = res.data.count;
          res.data.datas.forEach((item) => {
            if (item.del !== 1) {
              this.tableData.push(item);
              // //console.log(this.tableData);
            }
          });
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 获取批次列表数据
    async getBatchList() {
      const { data } = await this.$get(
        "/inventoryrecords/getInventoryrecords",
        { page: 1, limit: 1000 }
      );
      //console.log(data);
      if (data.state == "success") {
        this.batchList = data.datas;
      }
    },
    // 编辑
    updateClick(param1, param2) {
      this.boxPrompt = "编辑销售记录";
      this.updateForm = true;
      this.$nextTick(() => {
        this.upTableData = JSON.parse(JSON.stringify(param2));
      });
    },
    // 新增
    addMessage() {
      this.boxPrompt = "新增销售记录";
      this.updateForm = true;
      if (this.$refs["upTableData"] !== undefined) {
        this.$nextTick(() => {
          this.upTableData = this.upTableData;
          this.$refs["upTableData"].resetFields();
        });
      }
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/salesMan/delSales", {
            id: item.id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.requrstList();
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //保存
    saveForm() {
      let url = "/salesMan/addOrUpdateSales";
      let updata = this.upTableData;
      this.$refs["upTableData"].validate((valid) => {
        if (valid) {
          this.$get(url, updata).then((res) => {
            this.updateForm = false;
            //console.log(res);
            if (res.data.state == "success") {
              this.requrstList();
              this.$message.success("操作成功");
            } else {
              this.$message.info(res.data.msg);
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    //刷新
    refresh() {
      this.loading = true;
      if (!this.searchStatus) {
        this.requrstList();
      } else {
        this.searchs();
      }
    },
    //搜索
    search() {
      let name = this.logisticsForm.name;
      this.page = 1;
      this.loading = true;
      this.searchStatus = true;
      if (name == "" || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.names = "";
      this.logisticsForm.region = -1;
      this.requrstList();
    },
    // 查看二维码
    seeBtn(item) {
      this.codeShow = true;
      // //console.log(item);
      let items = item.batchNumber;
      // //console.log(items);
      let obj = "";
      for (obj of this.batchList) {
        if (items == obj.id) {
          this.batchID = obj.batchID;
          // //console.log(this.batchID);
        }
      }
      let url = this.sourCodeUrl + `?active=sixth&id=${this.batchID}`;
      //console.log(url);
      this.$nextTick(() => {
        this.codeShow = true;
        setTimeout(() => {
          this.$refs.qrCodeUrl.innerHTML = "";
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url,
            width: 230,
            height: 230,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 200);
      });
    },
    // 格式化批次
    formatterBatch(row) {
      // //console.log(row);
      let batchNumber = row.batchNumber;
      let batchList = this.batchList;
      for (var x = 0; x < batchList.length; x++) {
        if (batchList[x].id == batchNumber) {
          return batchList[x].batchNum;
        }
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "@/style/newBackTable.less";
.operation {
  display: flex;
  justify-content: center;
}
// #qrcode{
//   margin-left: 2vw; ;
// }
#qrcode {
        /deep/ img {
            width: 55%;
            margin: 0 auto;
        }
    }
</style>
